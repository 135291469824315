export const apiConfig = {
    powerPointEndPoint: 'https://idpowertoys-damien-back.azurewebsites.net'
}

export const msalConfig = {
    clientId: '220af23c-2063-4a08-b613-acf7dba571a6'
}

export const envConfig = {
    envName: ''
}
